







































































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent } from '@ag-grid-community/core';
import { sumBy } from 'lodash';
import ProspectService from '@/services/crm/prospect.service';
import CityService from '@/services/crm/city.service';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import SettingsModel from '@/models/crm/settings.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import dayjs from '@/plugins/dayjs';
import ClientModel from '@/models/crm/client.model';
import ProspectCityClientsModel from '@/models/crm/prospect-city-clients.model';
import SettingsService from '@/services/crm/settings.service';
import CityModel from '@/models/city.model';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';

type DataGridFilterConfig = {
  representative: string[] | undefined;
  lastBoughtPeriod: (Date | undefined)[];
  erpStatus: string | undefined;
  managementStatus: string | undefined;
};

type FeaturedCard = {
  title: string;
  value: string;
  class?: string;
  cols: {
    lg: number;
    md: number;
  };
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    ConfirmationDialog,
  },
})
export default class CrmProspectCityClients extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CityService)
  private cityService!: CityService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  dialogConfig: IKeyValue<IDialogConfig> = {
    detailsErrors: {
      show: false,
      message: '',
      color: '',
    },
  };

  settings: SettingsModel | null = null;

  grid: GridReadyEvent | null = null;

  situations = [];

  cityProspect: CityModel = new CityModel();

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataVencimento', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.client')}`,
        field: 'cliente',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.representative')}`,
        field: 'representante',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.erpStatus')}`,
        field: 'situacaoErp',
        maxWidth: 100,
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.managementStatus')}`,
        field: 'situacaoGestao',
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.address')}`,
        field: 'endereco',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.lastBoughtDate')}`,
        field: 'dataUltimaCompra',
        maxWidth: 155,
        valueGetter: (params): string => {
          if (params.data && params.data.dataUltimaCompra && params.data.dataUltimaCompra.toString().includes('1900')) {
            return '';
          }
          return GridHelper.valueGetter(params, DateHelper.formatToLocale);
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.lastBoughtValue')}`,
        field: 'valorUltimaCompra',
        valueFormatter: (params): string => (params.value
          ? this.$n(Number(Number(params.value).toFixed(2)), 'currency') : ''),
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.lastBoughtQuantity')}`,
        field: 'qtdePecasUltimaCompra',
        maxWidth: 140,
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.totalOrders')}`,
        field: 'qtdePedidos',
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.averageTicket')}`,
        field: 'ticketMedioValor',
        valueFormatter: (params): string => (params.value
          ? this.$n(Number(Number(params.value).toFixed(2)), 'currency') : ''),
      },
      {
        headerName: `${this.$t('crm.view.prospectCityClients.grid.averageQuantity')}`,
        field: 'mediaPecas',
        valueFormatter: (params): string => (params.value
          ? this.$n(Number(Number(params.value).toFixed(2)), 'decimal') : ''),
      },
    ],
  };

  filters: DataGridFilterConfig = {
    representative: undefined,
    erpStatus: undefined,
    managementStatus: undefined,
    lastBoughtPeriod: [undefined, undefined],
  };

  items: ProspectCityClientsModel[] = [];

  allItems: ProspectCityClientsModel[] = [];

  selected: ProspectCityClientsModel[] = [];

  predefinedLastBoughtFilterRanges: IDateRangeConfig[] = this.getDateRanges();

  openModalEmail = false;

  async mounted(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    this.loadItems();
    this.loadCityProspect();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ProspectCityClientsModel[]): void {
    this.selected = selected;
  }

  onExport(selected: ProspectCityClientsModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: `Clientes por cidade ${this.activeClient.cidade}`,
        fileName: ProspectService.generateProspectCityClientsInfoExportFilename(
          new Date(),
          this.activeClient.cidade || '',
        ),
      });
    }
  }

  openPopUpDetailsErrors(valueCard: string): void {
    let message = '';
    this.dialogConfig.detailsErrors.show = true;
    if ([`${this.$t('crm.view.prospectCityClients.card.nearestActiveClienteNotFound')}`,
    ].includes(valueCard)) {
      message = `${this.$t('crm.view.prospectCityClients.popUpDetailsErrors.noInfosClients')}`;
    } else {
      message = `${this.$t('crm.view.prospectCityClients.popUpDetailsErrors.noGeoLocation')}`;
    }
    this.dialogConfig.detailsErrors.message = message;
    this.dialogConfig.detailsErrors.color = 'grey';
  }

  get filteredItems(): ProspectCityClientsModel[] {
    let filteredItems = this.allItems;

    if (this.filters.representative && this.filters.representative.length > 0) {
      filteredItems = filteredItems.filter((item) => this.filters.representative?.includes(item.representante));
    }

    if (this.filters.erpStatus && this.filters.erpStatus.length > 0) {
      filteredItems = filteredItems.filter((item) => this.filters.erpStatus?.includes(item.situacaoErp));
    }

    if (this.filters.managementStatus && this.filters.managementStatus.length > 0) {
      filteredItems = filteredItems.filter((item) => this.filters.managementStatus?.includes(item.situacaoGestao));
    }

    if (this.filters.lastBoughtPeriod && this.filters.lastBoughtPeriod[0]) {
      const start = dayjs(this.filters.lastBoughtPeriod[0]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataUltimaCompra).isAfter(start) || dayjs(item.dataUltimaCompra).isSame(start),
      );
    }

    if (this.filters.lastBoughtPeriod && this.filters.lastBoughtPeriod[1]) {
      const end = dayjs(this.filters.lastBoughtPeriod[1]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataUltimaCompra).isBefore(end) || dayjs(item.dataUltimaCompra).isSame(end),
      );
    }

    return filteredItems;
  }

  get noClientsDescription(): string {
    return `${this.$t('crm.view.prospectCityClients.card.noClients')}`;
  }

  get noOrdersDescription(): string {
    return `${this.$t('crm.view.prospectCityClients.card.noOrders')}`;
  }

  get noInfoDescription(): string {
    return `${this.$t('crm.view.prospectCityClients.card.noInfo')}`;
  }

  get noCountyDescription(): string {
    return `${this.$t('crm.view.prospectCityClients.card.noCounty')}`;
  }

  get nearestActiveClientNotFound(): string {
    return `${this.$t('crm.view.prospectCityClients.card.nearestActiveClienteNotFound')}`;
  }

  get nearestActiveClientEmptyValue(): string {
    return this.settings?.flagGeoCodificacao ? this.nearestActiveClientNotFound : this.noInfoDescription;
  }

  get featuredCards(): FeaturedCard[] {
    let nearestActiveClient = this.noClientsDescription;
    let nearestActiveClientDistance = this.noClientsDescription;
    let activeClients = 0;
    let mostRecentOrderActiveClient = this.noOrdersDescription;
    let mostRecentOrderActiveClientDistance = this.noOrdersDescription;
    let mostRecentOrderValueActiveClient = this.noOrdersDescription;
    let countyClient = this.noCountyDescription;
    let countyClientPopulation = this.noCountyDescription;
    const lastPurchase = this.allItems[0];

    if (lastPurchase) {
      nearestActiveClient = lastPurchase.clienteAtivoMaisProximo
        ? lastPurchase.clienteAtivoMaisProximo.toString()
        : this.nearestActiveClientEmptyValue;
      nearestActiveClientDistance = lastPurchase.distanciaClienteAtivoMaisProximo
        ? lastPurchase.distanciaClienteAtivoMaisProximo.toString()
        : this.nearestActiveClientEmptyValue;
      activeClients = this.allItems.filter((item) => !item.situacaoErp.toLowerCase().includes('inativo')
        && item.situacaoErp.toLowerCase().includes('ativo')).length;
      mostRecentOrderActiveClient = lastPurchase.clienteAtivoPedidoMaisRecente
        ? lastPurchase.clienteAtivoPedidoMaisRecente
        : this.noOrdersDescription;
      mostRecentOrderActiveClientDistance = lastPurchase.distanciaClienteAtivoPedidoMaisRecente != null
        ? lastPurchase.distanciaClienteAtivoPedidoMaisRecente.toString()
        : this.nearestActiveClientEmptyValue;
      mostRecentOrderValueActiveClient = lastPurchase.valorClienteAtivoPedidoMaisRecente
        ? lastPurchase.valorClienteAtivoPedidoMaisRecente.toString()
        : this.noOrdersDescription;
    }
    countyClient = this.cityProspect.nome ? this.cityProspect.nome : this.noCountyDescription;
    countyClientPopulation = this.cityProspect.populacao
      ? this.cityProspect.populacao.toString()
      : this.noCountyDescription;

    const totals = {
      activeClients,
      nearestActiveClient,
      nearestActiveClientDistance,
      mostRecentOrderActiveClient,
      mostRecentOrderActiveClientDistance,
      mostRecentOrderValueActiveClient,
      countyClient,
      countyClientPopulation,
    };

    return [
      {
        title: `${this.$t('crm.view.prospectCityClients.card.countyClient')}`,
        value: totals.countyClient,
        cols: { lg: 6, md: 4 },
        class: 'text-h4 text-center pt-6',
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.countyClientPopulation')}`,
        value: totals.countyClientPopulation.toString(),
        cols: { lg: 6, md: 4 },
        class: 'text-h4 text-center pt-6',
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.activeClients')}`,
        value: totals.activeClients.toString(),
        cols: { lg: 2, md: 4 },
        class: 'text-h4 text-center pt-6',
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.nearestActiveClient')}`,
        value: totals.nearestActiveClient.toString(),
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.nearestActiveClientDistance')}`,
        value: `${
          [this.noClientsDescription, this.nearestActiveClientEmptyValue].includes(totals.nearestActiveClientDistance)
            ? totals.nearestActiveClientDistance
            : `${Number(totals.nearestActiveClientDistance).toFixed(1)} Km`
        }`,
        cols: { lg: 2, md: 4 },
        class: `${
          [this.noClientsDescription, this.nearestActiveClientEmptyValue].includes(totals.nearestActiveClientDistance)
            ? 'text-h7 pt-0'
            : 'text-h4 pt-6'
        } text-center`,
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.mostRecentOrderActiveClient')}`,
        value: totals.mostRecentOrderActiveClient,
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.mostRecentOrderActiveClientDistance')}`,
        value: `${
          [this.noOrdersDescription, this.nearestActiveClientEmptyValue]
            .includes(totals.mostRecentOrderActiveClientDistance)
            ? totals.mostRecentOrderActiveClientDistance
            : `${Number(totals.mostRecentOrderActiveClientDistance).toFixed(1)} Km`
        }`,
        cols: { lg: 2, md: 4 },
        class: `${
          [this.noOrdersDescription, this.nearestActiveClientEmptyValue]
            .includes(totals.mostRecentOrderActiveClientDistance)
            ? 'text-h7 pt-0'
            : 'text-h4 pt-2'
        } text-center`,
      },
      {
        title: `${this.$t('crm.view.prospectCityClients.card.mostRecentOrderValueActiveClient')}`,
        value: `${
          [this.noOrdersDescription, this.noInfoDescription].includes(totals.mostRecentOrderValueActiveClient)
            ? totals.mostRecentOrderValueActiveClient
            : this.$n(Number(Number(totals.mostRecentOrderValueActiveClient).toFixed(2)), 'currency')
        }`,
        cols: { lg: 2, md: 4 },
        class: `${
          totals.mostRecentOrderValueActiveClient === this.noOrdersDescription ? 'text-h5 pt-0' : 'text-h4 pt-2'
        } text-center `,
      },
    ];
  }

  get summary(): IKeyValue<number>[] | undefined {
    const items = this.filteredItems;

    if (!items || !items.length) {
      return undefined;
    }

    return [
      {
        valorLiquido: sumBy(items, 'valorLiquido'),
      },
    ];
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        case 'lastBoughtPeriod':
          if (this.filters[key] && (this.filters[key][0] || this.filters[key][1])) {
            active += 1;
          }
          break;
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  get representativeOptions() {
    return this.allItems.map((item) => item.representante);
  }

  get erpStatusOptions() {
    return this.allItems.map((item) => item.situacaoErp);
  }

  get managementStatusOptions() {
    return this.allItems.map((item) => item.situacaoGestao);
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  private async loadItems(): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.allItems = await this.prospectService.getProspectCityClientsInfo(
        this.activeClient.endNumero || '',
        this.activeClient.endRua || '',
        this.activeClient.cidade || '',
        this.activeClient.estado || '',
        this.activeClient.codCliente || '',
      );
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  private async loadCityProspect(): Promise<void> {
    this.cityProspect = await this.cityService.getCityProspect(
      this.activeClient.prospectId, this.activeClient.codCliente,
    );
  }

  onFilterChange(): void {
    this.loadItems();
  }
}
